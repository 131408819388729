import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { SubmitButton } from "&styled/button/button.component";
import axios from "axios";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";

const oneTimePurchaseUrl = process.env.REACT_APP_ONE_TIME_PURCHASE_URL;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "34px",
  p: 4,
};

interface IProps {
  open: string;
  handleClose: () => void;
}

export default function LogsModal({ open, handleClose }: IProps) {
  const [logs, setLogs] = useState<any[]>([]);
  async function callLogs(logId) {
    try {
      const response = await axios.get(`${oneTimePurchaseUrl}/auto-jobs/switch-logs/${logId}`);
      if (response?.status === 200) {
        return setLogs(response?.data?.data);
      }
      setLogs([
        {
          message: "Error fetching logs",
        },
      ]);
    } catch (error) {
      console.log(error);
      setLogs([
        {
          message: "Error fetching logs",
        },
      ]);
    }
  }
  useEffect(() => {
    if (open) {
      // for testing use this f65d65eb4c7ea45c
      callLogs(open);
    }
  }, [open]);
  return (
    <div>
      <Modal
        open={open ? true : false}
        onClose={handleClose}
        aria-labelledby="confirmation-modal"
        aria-describedby="confirmation-modal"
      >
        <Box sx={style}>
          <Box>
            <Box
              sx={{
                color: "#2e2e2e",
                fontSize: "20px",
                fontWeight: "bold",
                marginY: "1rem",
                textAlign: "center",
              }}
            >
              Switch Logs
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Typography variant="h5" align="center">
                    Title Fetch
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Typography variant="body1" style={{ margin: 0 }}>
                      Number of Attempts:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "24px", // Small size for circular element
                        height: "24px",
                        backgroundColor: "#3f8310",
                        color: "white",
                        borderRadius: "50%",
                        fontSize: "12px", // Small font size for text
                        textAlign: "center",
                      }}
                    >
                      {(logs ?? []).reduce((acc, log) => {
                        if (log?.type === "title-fetched") {
                          return acc + (log?.apiRequest?.length ?? 0);
                        }
                        return acc;
                      }, 0)}
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column" gap={2}>
                    {(logs ?? [])
                      ?.filter((log) => log?.type === "title-fetched")
                      ?.map((item) => {
                        return (
                          <Box key={item._id}>
                            {(item?.apiRequest ?? []).map((request, index) => {
                              return (
                                <Accordion
                                  key={index}
                                  sx={{
                                    maxHeight: 300,
                                    overflow: "auto",
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${item?._id}-content`}
                                    id={`panel${item?._id}-header`}
                                  >
                                    <Typography variant="h6">
                                      {moment(request?.request?.requestedDate).format("DD/MM/YYYY hh:mm:ss A")}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Box bgcolor={"#e0f7fa"} p={1}>
                                      <Typography variant="body1">Request:</Typography>
                                      {Object.entries(request?.request ?? {}).map(([key, value]) => (
                                        <Box key={key}>
                                          <Box display={"flex"} justifyContent={"space-between"}>
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {key}
                                            </Typography>
                                            <Typography variant="body2">{value}</Typography>
                                          </Box>
                                        </Box>
                                      ))}
                                    </Box>
                                    <Box mt={2} mb={2} p={1}>
                                      <Divider />
                                    </Box>
                                    <Box bgcolor={"#f1f8e9"} p={1}>
                                      <Typography variant="body1">Response:</Typography>
                                      {Object.entries(request?.response ?? {}).map(([key, value]) => (
                                        <Box key={key}>
                                          <Box display={"flex"} justifyContent={"space-between"}>
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {key}
                                            </Typography>
                                            {
                                              <Typography variant="body2">
                                                {typeof value == "string" ? value : JSON.stringify(value)}
                                              </Typography>
                                            }
                                          </Box>
                                        </Box>
                                      ))}
                                    </Box>
                                  </AccordionDetails>
                                </Accordion>
                              );
                            })}
                          </Box>
                        );
                      })}
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant="h5" align="center">
                    IBFT
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Typography variant="body1" style={{ margin: 0 }}>
                      Number of Attempts:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "24px", // Small size for circular element
                        height: "24px",
                        backgroundColor: "#3f8310",
                        color: "white",
                        borderRadius: "50%",
                        fontSize: "12px", // Small font size for text
                        textAlign: "center",
                      }}
                    >
                      {(logs ?? []).reduce((acc, log) => {
                        if (log?.type === "disbursal") {
                          return acc + (log?.apiRequest?.length ?? 0);
                        }
                        return acc;
                      }, 0)}
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column" gap={2}>
                    {(logs ?? [])
                      ?.filter((log) => log?.type === "disbursal")
                      ?.map((item) => {
                        return (
                          <Box key={item._id}>
                            {(item?.apiRequest ?? []).map((request, index) => {
                              return (
                                <Accordion
                                  key={index}
                                  sx={{
                                    maxHeight: 300,
                                    overflow: "auto",
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${item?._id}-content`}
                                    id={`panel${item?._id}-header`}
                                  >
                                    <Typography variant="h6">
                                      {moment(request?.request?.requestedDate).format("DD/MM/YYYY hh:mm:ss A")}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Box bgcolor={"#e0f7fa"} p={1}>
                                      <Typography variant="body1">Request:</Typography>
                                      {Object.entries(request?.request ?? {}).map(([key, value]) => (
                                        <Box key={key}>
                                          <Box display={"flex"} justifyContent={"space-between"}>
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {key}
                                            </Typography>
                                            <Typography variant="body2">{value}</Typography>
                                          </Box>
                                        </Box>
                                      ))}
                                    </Box>
                                    <Box mt={2} mb={2}>
                                      <Divider />
                                    </Box>
                                    <Box bgcolor={"#f1f8e9"} p={1}>
                                      <Typography variant="body1">Response:</Typography>
                                      {Object.entries(request?.response ?? {}).map(([key, value]) => (
                                        <Box key={key}>
                                          <Box key={key} display={"flex"} justifyContent={"space-between"}>
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {key}
                                            </Typography>
                                            {
                                              <Typography variant="body2">
                                                {typeof value == "string" ? value : JSON.stringify(value)}
                                              </Typography>
                                            }
                                          </Box>
                                        </Box>
                                      ))}
                                    </Box>
                                  </AccordionDetails>
                                </Accordion>
                              );
                            })}
                          </Box>
                        );
                      })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ width: "100%" }} mt={2}>
              <SubmitButton title="Close" handlePress={handleClose} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
