import * as React from "react";

export default function InfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 24 24"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        fill="none"
        stroke="#0070db94cbff"
        strokeWidth="2"
      />
      <line
        x1="12"
        y1="24"
        x2="12"
        y2="12"
        stroke="#0db94c"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="12" cy="8" r="1" fill="#0db94c" />
    </svg>
  );
}
